/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {layout} from "../contexts/layout-context"

import Header from "./header"
import Sidebar from "./sidebar"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let layoutClass = [];
  if (layout.sidebarOpened) {
    layoutClass.push("with-sidebar");
  }

  return (
    <>
      <div id="wrapper">
        <div id="main" className={layoutClass.join(" ")}>
          <div className="inner">
            <Header siteTitle={data.site.siteMetadata.title} />
            {children}
            <footer className="align-center">
              <ul className="icons">
                <li><a href="https://www.facebook.com/zao.impro" className="icon brands fa-facebook-f">
                  <span className="label">Facebook</span>
                </a></li>
                <li><a href="https://www.instagram.com/zao_lab/" className="icon brands fa-instagram">
                  <span className="label">Instagram</span>
                </a></li>
              </ul>
              <p><a href="https://zaotheater.us20.list-manage.com/subscribe?u=010e673dc6267aca985a1eab4&id=484e6cc628">Подпишитесь на нашу рассылку</a></p>
              <p>Creative Studio ZAO © {new Date().getFullYear()}, Разработано с <span>♥</span> <a href="https://www.gatsbyjs.org">Gatsby</a> & <a href="https://drupal.org">Drupal 8</a> (<a href='https://nikro.me'>Nikro</a>).</p>
            </footer>
          </div>
        </div>
        <Sidebar />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
