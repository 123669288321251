import { Link } from "gatsby"
import React, { Component } from "react"
import {LayoutContext, layout} from "../contexts/layout-context"
import Search from "./search/index"


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showActive: false,
      showSubmenu: false,
    };
  }

  toggleClass = () => {
    this.setState(prevState => ({
      showActive: !prevState.showActive,
    }));
    layout.sidebarOpened = !layout.sidebarOpened;
  };
  toggleSubMenuClass = () => {
    this.setState(prevState => ({
      showSubmenu: !prevState.showSubmenu,
    }));
  };

  render() {
    let toggleClass = [];
    let subMenuClass = ['opener'];
    if (!this.state.showActive) {
      toggleClass.push("inactive");
    }
    if (this.state.showSubmenu) {
      subMenuClass.push('active');
    }
    const searchIndices = [
      { name: `Articles`, title: `Articles`, hitComp: `ArticleHit` },
    ]
    return (
      <div id="sidebar" className={toggleClass.join(" ")}>
        <div className="inner">
          <section id="search" className="alt">
            <Search collapse indices={searchIndices} />
          </section>
          <nav id="menu">
            <header className="major">
              <h2>Меню</h2>
            </header>
            <ul>
              <li><Link to="/">Главная</Link></li>
              <li>
                <span className={subMenuClass.join(" ")} onClick={this.toggleSubMenuClass}>Концепт / Concept</span>
                <ul>
                  <li><Link to="/ru/about">Русский</Link></li>
                  <li><Link to="/en/about">English</Link></li>
                  <li><Link to="/ro/about">Română</Link></li>
                </ul>
              </li>
              <li><Link to="/category/proekty">Проекты</Link></li>
              <li><Link to="/category/karnaval">Карнавал</Link></li>
              <li><Link to="/category/spektakli">Спектакли</Link></li>
              <li><Link to="/donate">Донации / Donations</Link></li>
              <li><Link to="/contact">Контакт</Link></li>
            </ul>
          </nav>
          <footer id="footer">
            <p className="copyright">&copy; Creative Studio ZAO. Все права защищены.</p>
          </footer>

        </div>
        <a href="#" className="toggle" onClick={this.toggleClass}>Toggle</a>
      </div>
    )
  }
}
Sidebar.contextType = LayoutContext;

export default Sidebar
