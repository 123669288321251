import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"

export default connectSearchBox(({ refine, ...rest }) => (
  <form>
    <input
      type="text"
      placeholder="Поиск..."
      aria-label="Поиск"
      onChange={e => refine(e.target.value)}
    />
  </form>
))